import { GetLandingPageDataQuery, GetProjectsDataQuery } from '../../graphql-types';

type BuildersData = GetProjectsDataQuery | GetLandingPageDataQuery;

const initialComponents: BuildersData = {
  allNodeBodyBuilder: { nodes: [] },
  allNodeMasthead: { nodes: [] },
  allNodeTout: { nodes: [] },
  allNode2UpGrid: { nodes: [] },
  allNode3UpInformative: { nodes: [] },
  allNodeToutText: { nodes: [] },
  allNodeContentAdvertise: { nodes: [] },
  allNodeQuote: { nodes: [] },
  allNodeToutSimple: { nodes: [] },
  allNodeSimpleMedia: { nodes: [] },
  allNodeMediaGrid: { nodes: [] },
  allNodeCarousel: { nodes: [] },
  allNodeArticleTout: { nodes: [] },
  allNodeBodyContent: { nodes: [] },
  allNodeDownloadableContent: { nodes: [] },
  allNodeQuickLi: { nodes: [] },
  allNodeNewsGrid: { nodes: [] },
  allNodeArticlesGrid: { nodes: [] },
  allNodeTout5050: { nodes: [] },
  allNodeMasthead2022: { nodes: [] },
};

// eslint-disable-next-line max-params
function filterByPageReference(
  pageName: string,
  filteredData: BuildersData,
  componentsToFilter: BuildersData,
  filteredOnReferenceField: 'field_landing_page_reference' | 'field_project_reference'
): BuildersData {
  const newfilteredData = { ...filteredData };
  newfilteredData.allNodeBodyBuilder.nodes = componentsToFilter.allNodeBodyBuilder.nodes.filter(
    (bodyBuilderNode) =>
      bodyBuilderNode.relationships?.[filteredOnReferenceField]?.name === pageName
  );

  newfilteredData.allNodeMasthead.nodes = componentsToFilter.allNodeMasthead.nodes.filter(
    (mastheadNode) => mastheadNode.relationships?.[filteredOnReferenceField]?.name === pageName
  );

  newfilteredData.allNodeTout.nodes = componentsToFilter.allNodeTout.nodes.filter(
    (toutNode) => toutNode.relationships?.[filteredOnReferenceField]?.name === pageName
  );

  newfilteredData.allNode2UpGrid.nodes = componentsToFilter.allNode2UpGrid.nodes.filter(
    (twoUpNode) => twoUpNode.relationships?.[filteredOnReferenceField]?.name === pageName
  );

  newfilteredData.allNode3UpInformative.nodes =
    componentsToFilter.allNode3UpInformative.nodes.filter(
      (threeUpNode) => threeUpNode.relationships?.[filteredOnReferenceField]?.name === pageName
    );

  newfilteredData.allNodeToutText.nodes = componentsToFilter.allNodeToutText.nodes.filter(
    (toutTextNode) => toutTextNode.relationships?.[filteredOnReferenceField]?.name === pageName
  );

  newfilteredData.allNodeContentAdvertise.nodes =
    componentsToFilter.allNodeContentAdvertise.nodes.filter(
      (contentAdNode) => contentAdNode.relationships?.[filteredOnReferenceField]?.name === pageName
    );

  newfilteredData.allNodeQuote.nodes = componentsToFilter.allNodeQuote.nodes.filter(
    (quoteNode) => quoteNode.relationships?.[filteredOnReferenceField]?.name === pageName
  );

  newfilteredData.allNodeToutSimple.nodes = componentsToFilter.allNodeToutSimple.nodes.filter(
    (toutSimpleNode) => toutSimpleNode.relationships?.[filteredOnReferenceField]?.name === pageName
  );

  newfilteredData.allNodeSimpleMedia.nodes = componentsToFilter.allNodeSimpleMedia.nodes.filter(
    (simpleMediaNode) =>
      simpleMediaNode.relationships?.[filteredOnReferenceField]?.name === pageName
  );

  newfilteredData.allNodeMediaGrid.nodes = componentsToFilter.allNodeMediaGrid.nodes.filter(
    (mediaGridNode) => mediaGridNode.relationships?.[filteredOnReferenceField]?.name === pageName
  );

  newfilteredData.allNodeBodyContent.nodes = componentsToFilter.allNodeBodyContent.nodes.filter(
    (bodyContent) => bodyContent.relationships?.[filteredOnReferenceField]?.name === pageName
  );

  newfilteredData.allNodeDownloadableContent.nodes =
    componentsToFilter.allNodeDownloadableContent.nodes.filter(
      (downloadableContent) =>
        downloadableContent.relationships?.[filteredOnReferenceField]?.name === pageName
    );
  newfilteredData.allNodeQuickLi.nodes = componentsToFilter.allNodeQuickLi.nodes.filter(
    (quickLiNode) => quickLiNode.relationships?.[filteredOnReferenceField]?.name === pageName
  );

  newfilteredData.allNodeCarousel.nodes = componentsToFilter.allNodeCarousel.nodes.filter(
    (carouselNode) => carouselNode.relationships?.[filteredOnReferenceField]?.name === pageName
  );

  newfilteredData.allNodeArticleTout.nodes = componentsToFilter.allNodeArticleTout.nodes.filter(
    (articleTout) => articleTout.relationships?.[filteredOnReferenceField]?.name === pageName
  );

  newfilteredData.allNodeNewsGrid.nodes = componentsToFilter.allNodeNewsGrid.nodes.filter(
    (newsGrid) => newsGrid.relationships?.[filteredOnReferenceField]?.name === pageName
  );

  newfilteredData.allNodeArticlesGrid.nodes = componentsToFilter.allNodeNewsGrid.nodes.filter(
    (articlesGrid) => articlesGrid.relationships?.[filteredOnReferenceField]?.name === pageName
  );

  return newfilteredData;
}

export { initialComponents, filterByPageReference };
