import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import AutoOrder from './autoOrder';
import Layout from '../components/layout';
import ArticleTout from '../components/article/articleBanner';
import { GetAllProjectsQuery, GetProjectsDataQuery } from '../../graphql-types';
import PageTypeContext from '../context/pageTypeContext';
import PageType from '../context/pageType';
import { filterByPageReference, initialComponents } from './components';

const GET_PROJECTS_DATA = graphql`
  query GetProjectsData {
    allNodeBodyBuilder(
      filter: { relationships: { field_project_reference: { name: { ne: null } } } }
    ) {
      ...CompleteBodyBuilderItems
    }
    allNodeMasthead(
      filter: { relationships: { field_project_reference: { name: { ne: null } } } }
    ) {
      ...CompleteMastheadsFields
    }
    allNodeTout(filter: { relationships: { field_project_reference: { name: { ne: null } } } }) {
      ...CompleteToutFields
    }
    allNode2UpGrid(filter: { relationships: { field_project_reference: { name: { ne: null } } } }) {
      ...Complete2upGridFields
    }
    allNode3UpInformative(
      filter: { relationships: { field_project_reference: { name: { ne: null } } } }
    ) {
      ...Complete3UpInformativeFields
    }
    allNodeToutText(
      filter: { relationships: { field_project_reference: { name: { ne: null } } } }
    ) {
      ...CompleteToutTextFields
    }
    allNodeContentAdvertise(
      filter: { relationships: { field_project_reference: { name: { ne: null } } } }
    ) {
      ...CompleteContentAdvertiseFields
    }
    allNodeQuote(filter: { relationships: { field_project_reference: { name: { ne: null } } } }) {
      ...CompleteQuoteFields
    }
    allNodeToutSimple(
      filter: { relationships: { field_project_reference: { name: { ne: null } } } }
    ) {
      ...CompleteToutSimpleFields
    }
    allNodeSimpleMedia(
      filter: { relationships: { field_project_reference: { name: { ne: null } } } }
    ) {
      ...CompleteSimpleMediaFields
    }
    allNodeMediaGrid(
      filter: { relationships: { field_project_reference: { name: { ne: null } } } }
    ) {
      ...CompleteMediaGridFields
    }
    allNodeCarousel(
      filter: { relationships: { field_project_reference: { name: { ne: null } } } }
    ) {
      ...CompleteCarouselFields
    }
    allNodeArticleTout(
      filter: { relationships: { field_project_reference: { name: { ne: null } } } }
    ) {
      ...CompleteArticleToutFields
    }
    allNodeBodyContent(
      filter: { relationships: { field_project_reference: { name: { ne: null } } } }
    ) {
      ...CompleteBodyContentFields
    }
    allNodeDownloadableContent(
      filter: { relationships: { field_project_reference: { name: { ne: null } } } }
    ) {
      ...CompleteDownloadableContentFields
    }
    allNodeQuickLi(filter: { relationships: { field_project_reference: { name: { ne: null } } } }) {
      ...CompleteQuickLinksFields
    }
    allNodeNewsGrid(
      filter: { relationships: { field_project_reference: { name: { ne: null } } } }
    ) {
      ...CompleteNewsGridFields
    }
    allNodeArticlesGrid(
      filter: { relationships: { field_project_reference: { name: { ne: null } } } }
    ) {
      ...CompleteArticlesGridFields
    }
    allNodeTout5050(
      filter: { relationships: { field_project_reference: { name: { ne: null } } } }
    ) {
      ...CompleteTout5050Fields
    }
    allNodeMasthead2022(
      filter: { relationships: { field_project_reference: { name: { ne: null } } } }
    ) {
      ...CompleteMasthead2022Fields
    }
  }
`;

interface Props {
  pageContext: {
    projectData?: GetAllProjectsQuery['allNodeProject']['nodes'][0];
  };
}

const ProjectBuilder: React.FC<Props> = ({ pageContext }) => {
  const data = useStaticQuery<GetProjectsDataQuery>(GET_PROJECTS_DATA);
  const [filteredData, setFilteredData] = useState<GetProjectsDataQuery>(initialComponents);

  const { projectData } = pageContext;
  const projectName = projectData?.relationships?.field_project_id?.name;

  useEffect(() => {
    if (projectName) {
      setFilteredData(
        filterByPageReference(projectName, filteredData, data, 'field_project_reference')
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectName, data]);

  if (!projectName) {
    return null;
  }

  return (
    <Layout
      seo={{
        'title': projectData?.title ?? '',
        'author': 'Deutsch LA',
        'keywords': 'read, deutsch',
      }}
    >
      <PageTypeContext.Provider value={{ pageType: PageType.Project }}>
        <AutoOrder data={filteredData} className="pb-18" id={projectName} />
      </PageTypeContext.Provider>
      <ArticleTout />
    </Layout>
  );
};

export default ProjectBuilder;
